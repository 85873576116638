<template>
  <div>
      <a-row type="flex">
          <a-col :flex="1">
      请选择分析类型
          </a-col>
          <a-col :flex="4">
<!-- 后台获取 /v1/nutrition_indicators/' 的子路由作为指令-->
<GetAllCMDOfAnApp CMDIndexUrl='/v1/nutrition_indicators/'/>
          </a-col>
      </a-row>
      <a-row type="flex" style="margin-top: 10px"
      >
      <a-col :flex="1">
      请填写或选择执行参数
          </a-col>
        <a-col :flex="4">
<!-- 获取执行相关链接的参数 -->
<GetExecCMDAttr />
</a-col>
      </a-row>
      <a-row type="flex" style="margin-top: 10px">
          <a-col :flex="1">
      确认执行
          </a-col>
          <a-col :flex="4">  
      <ExecCMD />
      <ExecUploadCMD />
</a-col>
      </a-row>
    <ShowExecResult />
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import GetAllCMDOfAnApp from '@/components/execcmd/GetAllCMDOfAnApp'
import GetExecCMDAttr from '@/components/execcmd/GetExecCMDAttr'
import ExecCMD from '@/components/execcmd/ExecCMD'
import ShowExecResult from '@/components/execcmd/ShowExecResult'
import ExecUploadCMD from '@/components/execcmd/ExecUploadCMD'
export default defineComponent({
    components: {
        GetAllCMDOfAnApp,
        GetExecCMDAttr,
        ExecCMD,
        ShowExecResult,
        ExecUploadCMD,
    },
    setup() {

    }
})
</script>

<style>

</style>